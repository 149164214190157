export const summaryPageTopMenu = {
	intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap Components',
		path: '#bootstrap',
		icon: 'BootstrapFill',
		subMenu: null,
	},
	storybook: {
		id: 'storybook',
		text: 'Storybook',
		path: '#storybook',
		icon: 'CustomStorybook',
		subMenu: null,
	},
	formik: {
		id: 'formik',
		text: 'Formik',
		path: '#formik',
		icon: 'CheckBox',
		subMenu: null,
	},
	apex: {
		id: 'apex',
		text: 'Apex Charts',
		path: '#apex',
		icon: 'AreaChart',
		subMenu: null,
	},
};

export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
	dashboardProject: {
		id: 'dashboardProject',
		text: 'Dashboard Projects',
		path: 'project-management/list',
		icon: 'AutoStories',
		notification: true,
		subMenu: null,
	},
	dashboardBooking: {
		id: 'dashboard-booking',
		text: 'Dashboard Booking',
		path: 'dashboard-booking',
		icon: 'emoji_transportation',
		subMenu: null,
	},
	crmDashboard: {
		id: 'crmDashboard',
		text: 'CRM Dashboard',
		path: 'crm/dashboard',
		icon: 'RecentActors',
	},
	summary: {
		id: 'summary',
		text: 'Summary',
		path: 'summary',
		icon: 'sticky_note_2',
		subMenu: null,
	},
};

export const demoPagesMenu = {
	pages: {
		id: 'pages',
		text: 'Pages',
		icon: 'Extension',
	},
	listPages: {
		id: 'listPages',
		text: 'List Pages',
		path: 'list-pages',
		icon: 'Dvr',
		subMenu: {
			listBoxed: {
				id: 'listBoxed',
				text: 'Boxed List',
				path: 'list-pages/boxed-list',
				icon: 'ViewArray',
			},
			listFluid: {
				id: 'listFluid',
				text: 'Fluid List',
				path: 'list-pages/fluid-list',
				icon: 'ViewDay',
			},
		},
	},
	gridPages: {
		id: 'gridPages',
		text: 'Grid Pages',
		path: 'grid-pages',
		icon: 'Window',
		subMenu: {
			gridBoxed: {
				id: 'gridBoxed',
				text: 'Boxed Grid',
				path: 'grid-pages/boxed',
				icon: 'ViewArray',
			},
			gridFluid: {
				id: 'gridFluid',
				text: 'Fluid Grid',
				path: 'grid-pages/fluid',
				icon: 'ViewDay',
			},
		},
	},
	editPages: {
		id: 'editPages',
		text: 'Edit Pages',
		path: 'edit-pages',
		icon: 'drive_file_rename_outline ',
		subMenu: {
			editModern: {
				id: 'editModern',
				text: 'Modern Edit',
				path: 'edit-pages/modern',
				icon: 'AutoAwesomeMosaic',
				notification: 'primary',
			},
			editBoxed: {
				id: 'editBoxed',
				text: 'Boxed Edit',
				path: 'edit-pages/boxed',
				icon: 'ViewArray',
			},
			editFluid: {
				id: 'editFluid',
				text: 'Fluid Edit',
				path: 'edit-pages/fluid',
				icon: 'ViewDay',
			},
			editWizard: {
				id: 'editWizard',
				text: 'Wizard Edit',
				path: 'edit-pages/wizard',
				icon: 'LinearScale',
			},
			editInCanvas: {
				id: 'editInCanvas',
				text: 'In Canvas Edit',
				path: 'edit-pages/in-canvas',
				icon: 'VerticalSplit',
			},
			editInModal: {
				id: 'editInModal',
				text: 'In Modal Edit',
				path: 'edit-pages/in-modal',
				icon: 'PictureInPicture',
			},
		},
	},
	singlePages: {
		id: 'singlePages',
		text: 'Single Pages',
		path: 'single-pages',
		icon: 'Article',
		subMenu: {
			boxedSingle: {
				id: 'boxedSingle',
				text: 'Boxed',
				path: 'single-pages/boxed',
				icon: 'ViewArray',
			},
			fluidSingle: {
				id: 'fluidSingle',
				text: 'Fluid',
				path: 'single-pages/fluid',
				icon: 'ViewDay',
			},
		},
	},
	pricingTable: {
		id: 'pricingTable',
		text: 'Pricing Table',
		path: 'pricing-table',
		icon: 'Local Offer',
	},

	app: {
		id: 'app',
		text: 'Apps',
		icon: 'Extension',
	},
	projectManagement: {
		id: 'projectManagement',
		text: 'Project Management',
		path: 'project-management',
		icon: 'AutoStories',
		subMenu: {
			list: {
				id: 'list',
				text: 'Projects',
				path: 'project-management/list',
				icon: 'AutoStories',
			},
			itemID: {
				id: 'projectID',
				text: 'projectID',
				path: 'project-management/project',
				hide: true,
			},
			item: {
				id: 'item',
				text: 'Project',
				path: 'project-management/project/1',
				icon: 'Book',
			},
		},
	},
	knowledge: {
		id: 'knowledge',
		text: 'Knowledge',
		path: 'knowledge',
		icon: 'AutoStories',
		subMenu: {
			grid: {
				id: 'grid',
				text: 'Knowledge Grid',
				path: 'knowledge/grid',
				icon: 'AutoStories',
			},
			itemID: {
				id: 'itemID',
				text: 'itemID',
				path: 'knowledge/item',
				hide: true,
			},
			item: {
				id: 'item',
				text: 'Item',
				path: 'knowledge/item/1',
				icon: 'Book',
			},
		},
	},
	sales: {
		id: 'sales',
		text: 'Sales',
		path: 'sales',
		icon: 'Store',
		subMenu: {
			dashboard: dashboardPagesMenu.dashboard,
			salesList: {
				id: 'products',
				text: 'Sales List',
				path: 'sales/sales-list',
				icon: 'FactCheck',
			},
			productsGrid: {
				id: 'productsGrid',
				text: 'Products Grid',
				path: 'sales/grid',
				icon: 'CalendarViewMonth',
			},
			productID: {
				id: 'productID',
				text: 'productID',
				path: 'sales/product',
				hide: true,
			},
			product: {
				id: 'product',
				text: 'Product',
				path: 'sales/product/1',
				icon: 'QrCode2',
			},
			transactions: {
				id: 'transactions',
				text: 'Transactions',
				path: 'sales/transactions',
				icon: 'PublishedWithChanges',
			},
		},
	},
	appointment: {
		id: 'appointment',
		text: 'Appointment',
		path: 'appointment',
		icon: 'Today',
		subMenu: {
			dashboard: dashboardPagesMenu.dashboardBooking,
			calendar: {
				id: 'calendar',
				text: 'Calendar',
				path: 'appointment/calendar',
				icon: 'EditCalendar',
				notification: true,
			},
			employeeList: {
				id: 'employeeList',
				text: 'Employee List',
				path: 'appointment/employee-list',
				icon: 'PersonSearch',
			},
			employeeID: {
				id: 'employeeID',
				text: 'employeeID',
				path: 'appointment/employee',
				hide: true,
			},
			employee: {
				id: 'employee',
				text: 'Employee',
				path: 'appointment/employee/1',
				icon: 'QrCode2',
			},
			appointmentList: {
				id: 'appointmentList',
				text: 'Appointment List',
				path: 'appointment/appointment-list',
				icon: 'Event',
			},
		},
	},
	crm: {
		id: 'crm',
		text: 'CRM',
		path: 'crm',
		icon: 'Contacts',
		subMenu: {
			dashboard: {
				id: 'dashboard',
				text: 'CRM Dashboard',
				path: 'crm/dashboard',
				icon: 'RecentActors',
			},
			customersList: {
				id: 'customersList',
				text: 'Customers',
				path: 'crm/customers',
				icon: 'PersonSearch',
			},
			customerID: {
				id: 'customerID',
				text: 'customerID',
				path: 'crm/customer',
				hide: true,
			},
			customer: {
				id: 'customer',
				text: 'Customer',
				path: 'crm/customer/1',
				icon: 'Badge',
			},
			// sales: {
			// 	id: 'sales',
			// 	text: 'Sales',
			// 	path: 'crm/sales',
			// 	icon: 'Storefront',
			// },
			// invoiceID: {
			// 	id: 'invoiceID',
			// 	text: 'invoiceID',
			// 	path: 'crm/invoice',
			// 	hide: true,
			// },
			// invoice: {
			// 	id: 'invoice',
			// 	text: 'Invoice',
			// 	path: 'crm/invoice/1',
			// 	icon: 'Receipt',
			// },
		},
	},
	chat: {
		id: 'chat',
		text: 'Chat',
		path: 'chat',
		icon: 'Forum',
		subMenu: {
			withListChat: {
				id: 'withListChat',
				text: 'With List',
				path: 'chat/with-list',
				icon: 'Quickreply',
			},
			onlyListChat: {
				id: 'onlyListChat',
				text: 'Only List',
				path: 'chat/only-list',
				icon: 'Dns',
			},
		},
	},

	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth-pages/login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up',
		icon: 'PersonAdd',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},
};

export const pageLayoutTypesPagesMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'Page Layout Types',
	},
	blank: {
		id: 'blank',
		text: 'Blank',
		path: 'page-layouts/blank',
		icon: 'check_box_outline_blank ',
	},
	pageLayout: {
		id: 'pageLayout',
		text: 'Page Layout',
		path: 'page-layouts',
		icon: 'BackupTable',
		subMenu: {
			headerAndSubheader: {
				id: 'headerAndSubheader',
				text: 'Header & Subheader',
				path: 'page-layouts/header-and-subheader',
				icon: 'ViewAgenda',
			},
			onlyHeader: {
				id: 'onlyHeader',
				text: 'Only Header',
				path: 'page-layouts/only-header',
				icon: 'ViewStream',
			},
			onlySubheader: {
				id: 'onlySubheader',
				text: 'Only Subheader',
				path: 'page-layouts/only-subheader',
				icon: 'ViewStream',
			},
			onlyContent: {
				id: 'onlyContent',
				text: 'Only Content',
				path: 'page-layouts/only-content',
				icon: 'WebAsset',
			},
		},
	},
	asideTypes: {
		id: 'asideTypes',
		text: 'Aside Types',
		path: 'aside-types',
		icon: 'Vertical Split',
		subMenu: {
			defaultAside: {
				id: 'defaultAside',
				text: 'Default Aside',
				path: 'aside-types/default-aside',
				icon: 'ViewQuilt',
			},
			minimizeAside: {
				id: 'minimizeAside',
				text: 'Minimize Aside',
				path: 'aside-types/minimize-aside',
				icon: 'View Compact',
			},
		},
	},
};

export const dummyMenu = {
	businessIntelligence: {
		id: 'businessIntelligence',
		text: 'Business Intelligence',
		icon: 'CustomStatusUp',
		path: '/business-intelligence',
		subMenu: {
			reportSummary: {
				id: 'reportSummary',
				text: 'Report Summary',
				path: '/business-intelligence/report-summary',
				subMenu: {
					revenueSummary: {
						id: 'revenueSummary',
						text: 'Sales & Revenue',
						path: '/business-intelligence/report-summary/revenue-summary',
						hasPage: true,
						subMenu: {
							hotel: {
								id: 'hotel',
								text: 'Hotel',
								path: '/business-intelligence/report-summary/revenue-summary/hotel',
							},
							rooms: {
								id: 'rooms',
								text: 'Rooms',
								path: '/business-intelligence/report-summary/revenue-summary/rooms',
							},
							fnb: {
								id: 'fnb',
								text: 'Food & Beverages',
								path: '/business-intelligence/report-summary/revenue-summary/fnb',
							},
							others: {
								id: 'others',
								text: 'Others',
								path: '/business-intelligence/report-summary/revenue-summary/others',
							},
							consolidate: {
								id: 'consolidate',
								text: 'Consolidate',
								path: '/business-intelligence/report-summary/revenue-summary/consolidate',
							},
						},
					},
					consolidation: {
						id: 'consolidation',
						text: 'Consolidation',
						path: '/business-intelligence/report-summary/consolidation',
						subMenu: {
							unitHotel: {
								id: 'unitHotel',
								text: 'Unit Hotel',
								path: '/business-intelligence/report-summary/consolidation/unitHotel',
							},
							consolidationBrand: {
								id: 'consolidationBrand',
								text: 'Brand',
								path: '/business-intelligence/report-summary/consolidation/consolidationBrand',
							},
						},
					},
					financeAccountingSummary: {
						id: 'financeAccountingSummary',
						text: 'Finance & Accounting',
						path: '/business-intelligence/report-summary/finance-summary',
						hasPage: true,
						subMenu: {
							hotel: {
								id: 'hotel',
								text: 'Hotel',
								path: '/business-intelligence/report-summary/finance-summary/hotel',
							},
							rooms: {
								id: 'rooms',
								text: 'Rooms',
								path: '/business-intelligence/report-summary/finance-summary/rooms',
							},
							fnb: {
								id: 'fnb',
								text: 'Food & Beverages',
								path: '/business-intelligence/report-summary/finance-summary/fnb',
							},
							others: {
								id: 'others',
								text: 'Others',
								path: '/business-intelligence/report-summary/finance-summary/others',
							},
							consolidate: {
								id: 'consolidate',
								text: 'Consolidate',
								path: '/business-intelligence/report-summary/finance-summary/consolidate',
							},
						}
					},
					marketingPageSummary: {
						id: 'marketingPageSummary',
						text: 'Marketing',
						path: '/business-intelligence/report-summary/marketing-summary',
					},
					humanCapitalSummary: {
						id: 'humanCapitalSummary',
						text: 'Human Capital',
						path: '/business-intelligence/report-summary/humancapital-summary',
					},
					businessDevelopmentSummary: {
						id: 'businessDevelopmentSummary',
						text: 'Business Development',
						path: '/business-intelligence/report-summary/businessdevelopment-summary',
					},
					operationalSummary: {
						id: 'operationalSummary',
						text: 'Operational',
						path: '/business-intelligence/report-summary/op-summary',
					},
				},
			},
			// systemIntegration: {
			// 	id: 'systemIntegration',
			// 	text: 'System Integration',
			// 	path: '/business-intelligence/system-integration',
			// 	subMenu: {
			// 		humanCapitalManagementSystem: {
			// 			id: 'humanCapitalManagementSystem',
			// 			text: 'Human Capital Management System',
			// 			path: '/business-intelligence/system-integration/humanCapitalManagementSystem'
			// 		},
			// 		salesRevenueManagementSystem: {
			// 			id: 'salesRevenueManagementSystem',
			// 			text: 'Sales & Revenue Management System',
			// 			path: '/business-intelligence/system-integration/salesRevenueManagementSystem'
			// 		},
			// 		financeAccountingManagementSystem: {
			// 			id: 'financeAccountingManagementSystem',
			// 			text: 'Finance & Accounting Management System',
			// 			path: '/business-intelligence/system-integration/financeAccountingManagementSystem'
			// 		},
			// 		operationManagementSystem: {
			// 			id: 'operationManagementSystem',
			// 			text: 'Operation Management System',
			// 			path: '/business-intelligence/system-integration/operationManagementSystem'
			// 		},
			// 		marketingManagementSystem: {
			// 			id: 'marketingManagementSystem',
			// 			text: 'Marketing Management System',
			// 			path: '/business-intelligence/system-integration/marketingManagementSystem'
			// 		},
			// 		businessDevelopmentManagementSystem: {
			// 			id: 'businessDevelopmentManagementSystem',
			// 			text: 'Business Development Management System',
			// 			path: '/business-intelligence/system-integration/businessDevelopmentManagementSystem'
			// 		},
			// 	}

			// }
		},
	},
	salesRevenue: {
		id: 'salesRevenue',
		text: 'Sales & Revenue',
		icon: 'CustomChart',
		path: '/sales-revenue',
		hasPage: true,
		subMenu: {
			dataVisualization: {
				id: 'dataVisualization',
				text: 'Data Visualization',
				path: '/sales-revenue/data-visualization',
				subMenu: {
					revenueInsight: {
						id: 'revenueInsight',
						text: 'Revenue Insight',
						path: '/sales-revenue/data-visualization/revenue-insight',
					},
					marketInsight: {
						id: 'marketInsight',
						text: 'Market insight',
						path: '/sales-revenue/data-visualization/market-insight',
					},
					hotelRateInsight: {
						id: 'hotelRateInsight',
						text: 'Hotel Rate Insight',
						path: '/sales-revenue/data-visualization/hotel-rate-insight',
					},
					parityRateInsight: {
						id: 'parityRateInsight',
						text: 'Parity Rate Insight',
						path: '/sales-revenue/data-visualization/parity-rate-insight',
					},
					salesDataVisualization: {
						id: 'salesDataVisualization',
						text: 'Sales',
						path: '/sales-revenue/data-visualization/sales-data-visualization',
						subMenu: {
							groupByDemographic: {
								id: 'groupByDemographic',
								text: 'Group By Demographic',
								path: '/sales-revenue/data-visualization/sales-data-visualization/demographic',
							},
							groupByBrand: {
								id: 'groupByBrand',
								text: 'Group By Brand',
								path: '/sales-revenue/data-visualization/sales-data-visualization/Brand',
							},
							groupByCorporate: {
								id: 'groupByCorporate',
								text: 'Group By Corporate',
								path: '/sales-revenue/data-visualization/sales-data-visualization/corporate',
							},
						},
					},
					forecasting: {
						id: 'forecasting',
						text: 'Forecasting',
						path: '/sales-revenue/data-visualization/forecasting',
					},
				},
			},
			customerData: {
				id: 'customerData',
				text: 'Customer Data Management',
				path: '/sales-revenue/customer-data-management',
			},
			propertyManagement: {
				id: 'propertyManagement',
				text: 'Property Management',
				path: '/sales-revenue/property-management',
			},
			channelManager: {
				id: 'channelManager',
				text: 'Channel Manager',
				path: '/sales-revenue/channel-management',
			},
			bookingEngine: {
				id: 'bookingEngine',
				text: 'Booking Engine',
				path: '/sales-revenue/book-engine',
			},
		},
	},
	financeAccounting: {
		id: 'financeAccounting',
		text: 'Finance & Accounting',
		path: '/finance-accounting',
		icon: 'CustomCard',
		hasPage: true,
		subMenu: {
			hotelSummary: {
				id: 'hotelSummary',
				text: 'Hotel Summary',
				path: '/finance-accounting/hotelSummary',
				subMenu: {
					salesRevenue: {
						id: 'salesRevenue',
						text: 'Sales & Revenue',
						path: '/finance-accounting/hotelSummary/sales-revenue',
					},
					hotel: {
						id: 'hotel',
						text: 'Hotel',
						path: '/finance-accounting/hotelSummary/hotel',
					},
					rooms: {
						id: 'rooms',
						text: 'Rooms',
						path: '/finance-accounting/hotelSummary/rooms',
					},
					fnb: {
						id: 'fnb',
						text: 'Food & Beverages',
						path: '/finance-accounting/hotelSummary/fnb',
					},
					others: {
						id: 'others',
						text: 'Others',
						path: '/finance-accounting/hotelSummary/others',
					},
					consolidate: {
						id: 'consolidate',
						text: 'Consolidate',
						path: '/finance-accounting/hotelSummary/consolidate',
					},
				},
			},
			financeOperation: {
				id: 'financeOperation',
				text: 'Finance Operation',
				path: '/finance-accounting/finance-operation',
				subMenu: {
					dailyHospitalBasedReports: {
						id: 'dailyHospitalBasedReports',
						text: 'Daily Hospital Based Reports',
						path: '/finance-accounting/finance-operation/daily-hospital',
					},
					activityTracking: {
						id: 'activityTracking',
						text: 'Activity Tracking',
						path: '/finance-accounting/finance-operation/activity-tracking',
					},
					pmsIntegration: {
						id: 'pmsIntegration',
						text: 'PMS Integration',
						path: '/finance-accounting/finance-operation/pms-integration',
					},
					budgetForecastComparison: {
						id: 'budgetForecastComparison',
						text: 'Budget & Forecast Comparison',
						path: '/finance-accounting/finance-operation/budget-forecast',
					},
					benchmarking: {
						id: 'benchmarking',
						text: 'Benchmarking',
						path: '/finance-accounting/finance-operation/benchmarking',
					},
					operationalBudget: {
						id: 'operationalBudget',
						text: 'Operational Budget',
						path: '/finance-accounting/finance-operation/operational-budget',
					},
				},
			},
			accounting: {
				id: 'accounting',
				text: 'Accounting',
				path: '/finance-accounting/accounting',
				subMenu: {
					multiPropertyManagement: {
						id: 'multiPropertyManagement',
						text: 'Multi Property Management',
						path: '/finance-accounting/accounting/multiPropertyManagement',
					},
					accountRecievableAutomation: {
						id: 'accountRecievableAutomation',
						text: 'Account Recieveable Automation',
						path: '/finance-accounting/accounting/accountRecievableAutomation',
					},
					accountPayableAutomation: {
						id: 'accountPayableAutomation',
						text: 'Benchmarking',
						path: '/finance-accounting/accounting/accountPayableAutomation',
					},
					accountingForecast: {
						id: 'accountingForecast',
						text: 'Forecast',
						path: '/finance-accounting/accounting/accountingForecast',
					},
					accountingInventory: {
						id: 'accountingInventory',
						text: 'Inventory',
						path: '/finance-accounting/accounting/accountingInventory',
					},
					financialReports: {
						id: 'financialReports',
						text: 'Financial Reports',
						path: '/finance-accounting/accounting/financialReports',
					},
					expenseAnalysis: {
						id: 'expenseAnalysis',
						text: 'Expense Analysis',
						path: '/finance-accounting/accounting/expenseAnalysis',
					},
					generalLedger: {
						id: 'generalLedger',
						text: 'General Ledger',
						path: '/finance-accounting/accounting/generalLedger',
					},
					importExport: {
						id: 'importExport',
						text: 'Import/export',
						path: '/finance-accounting/accounting/importExport',
					},
					journal: {
						id: 'journal',
						text: 'Journal',
						path: '/finance-accounting/accounting/journal',
					},
					payroll: {
						id: 'payroll',
						text: 'Payroll',
						path: '/finance-accounting/accounting/payroll',
					},
				},
			},
		},
	},
	marketingPage: {
		id: 'marketing',
		text: 'Marketing',
		path: '/marketing-page',
		icon: 'CustomGraph',
		hasPage: true,
		subMenu: {
			wanderlust: {
				id: 'wanderlust',
				text: 'Wanderlust (Membership)',
				path: '/marketing-page/wanderlust',
				hasPage: true,
				subMenu: {
					memberGrowth: {
						id: 'memberGrowth',
						text: 'Member Growth',
						path: '/marketing-page/wanderlust/memberGrowth',
					},
					revenue: {
						id: 'revenue',
						text: 'Revenue',
						path: '/marketing-page/wanderlust/revenue',
					},
					totalMember: {
						id: 'totalMember',
						text: 'Total Member',
						path: '/marketing-page/wanderlust/totalMember',
					},
				},
			},
			reputationManagement: {
				id: 'reputationManagement',
				text: 'Reputation Management',
				path: '/marketing-page/reputationManagement',
				subMenu: {
					otaReputation: {
						id: 'otaReputation',
						text: 'OTA Reputation',
						path: '/marketing-page/reputationManagement/otaReputation',
					},
					commentManagement: {
						id: 'commentManagement',
						text: 'Comment Management',
						path: '/marketing-page/reputationManagement/commentManagement',
					},
				},
			},
			marketingCampaign: {
				id: 'marketingCampaign',
				text: 'Marketing Campaign Management',
				path: '/marketing-page/marketing-campaign',
				subMenu: {
					promotions: {
						id: 'promotions',
						text: 'Promotions',
						path: '/marketing-page/marketing-campaign/promotions',
					},
					discount: {
						id: 'discount',
						text: 'Discount',
						path: '/marketing-page/marketing-campaign/discount',
					},
				},
			},
			reputationReview: {
				id: 'reputationReview',
				text: 'Reputation & Review',
				path: '/marketing-page/reputation-review',
				subMenu: {
					reviewIntegrationRevenueSystem: {
						id: 'reviewIntegrationRevenueSystem',
						text: 'Integration with Revenue System',
						path: '/marketing-page/reputation-review/reviewIntegrationRevenueSystem',
					},
				},
			},
			guestManagement: {
				id: 'guestManagement',
				text: 'Guest management',
				path: '/marketing-page/guest-management',
				subMenu: {
					integrationRevenueSystem: {
						id: 'integrationRevenueSystem',
						text: 'Integration with Revenue System',
						path: '/marketing-page/guest-management/integrationRevenueSystem',
					},
					guestProfiling: {
						id: 'guestProfiling',
						text: 'Guest Profiling',
						path: '/marketing-page/guest-management/guestProfiling',
					},
					guestSegmentation: {
						id: 'guestSegmentation',
						text: 'Guest Segmentation',
						path: '/marketing-page/guest-management/guestSegmentation',
					},
				},
			},
			digitalMarketingManagement: {
				id: 'digitalMarketingManagement',
				text: 'Digital Marketing Management',
				path: '/marketing-page/digital-marketing-management',
				subMenu: {
					semAnalytics: {
						id: 'semAnalytics',
						text: 'SEM Analytics',
						path: '/marketing-page/digital-marketing-management/semAnalytics',
					},
					seoAnalytics: {
						id: 'seoAnalytics',
						text: 'SEO Analytics',
						path: '/marketing-page/digital-marketing-management/seoAnalytics',
					},
					socialMediaAnalytics: {
						id: 'socialMediaAnalytics',
						text: 'Social Media Analytics',
						path: '/marketing-page/digital-marketing-management/socialMediaAnalytics',
					},
				},
			},
			crm: {
				id: 'crm',
				text: 'CRM',
				path: '/marketing-page/crm',
				subMenu: {
					loyaltyProgram: {
						id: 'loyaltyProgram',
						text: 'Loyalty Program - Membership',
						path: '/marketing-page/crm/loyaltyProgram',
					},
					loyaltyPoint: {
						id: 'loyaltyPoint',
						text: 'Loyalty Point',
						path: '/marketing-page/crm/loyaltyPoint',
					},
				},
			},
		},
	},
	humanCapital: {
		id: 'humanCapital',
		text: 'Human Capital',
		path: '/human-capital',
		icon: 'CustomPerson',
		hasPage: true,
		subMenu: {
			hris: {
				id: 'hris',
				text: 'HRIS',
				path: '/human-capital/hris',
				hasPage: true,
				subMenu: {
					talentPoolModule: {
						id: 'talentPoolModule',
						text: 'Talent Pool Module',
						path: '/human-capital/hris/talentPoolModule',
					},
					candidateModule: {
						id: 'candidateModule',
						text: 'Candidate Module',
						path: '/human-capital/hris/candidateModule',
					},
					employeeModule: {
						id: 'employeeModule',
						text: 'Employee Module',
						path: '/human-capital/hris/employeeModule',
						hasPage: true,
						subMenu: {
							payrollModule: {
								id: 'payrollModule',
								text: 'Payroll Module',
								path: '/human-capital/hris/employeeModule/payrollModule',
							},
							projectModule: {
								id: 'projectModule',
								text: 'Project Module',
								path: '/human-capital/hris/employeeModule/projectModule',
							},
							taskScheduleModule: {
								id: 'taskScheduleModule',
								text: 'Task Schedule Module',
								path: '/human-capital/hris/employeeModule/taskScheduleModule',
							},
							KPIModule: {
								id: 'KPIModule',
								text: 'KPI Module',
								path: '/human-capital/hris/employeeModule/KPIModule',
							},
							personalResumeModule: {
								id: 'personalResumeModule',
								text: 'Personal Resume Module',
								path: '/human-capital/hris/employeeModule/personalResumeModule',
							},
							contractModule: {
								id: 'contractModule',
								text: 'Contract Module',
								path: '/human-capital/hris/employeeModule/contractModule',
							},
						},
					},
					// contentTeamModule: {
					// 	id: 'contentTeamModule',
					// 	text: "Content's Team Module",
					// 	path: '/human-capital/hris/contentTeamModule',
					// },
					surveyModule: {
						id: 'surveyModule',
						text: 'Survey Module',
						path: '/human-capital/hris/surveyModule',
					},
					// faqModule: {
					// 	id: 'faqModule',
					// 	text: 'FAQ Module',
					// 	path: '/human-capital/hris/faqModule',
					// },
					// yearlyWorkingDayModule: {
					// 	id: 'yearlyWorkingDayModule',
					// 	text: 'Yearly Working Module',
					// 	path: '/human-capital/hris/yearlyWorkingDayModule',
					// },
					// onboardingModule: {
					// 	id: 'onboardingModule',
					// 	text: 'Onboarding Module',
					// 	path: '/human-capital/hris/onboardingModule',
					// },
					// communityModule: {
					// 	id: 'communityModule',
					// 	text: 'Community Module',
					// 	path: '/human-capital/hris/communityModule',
					// },
					onlineTeam: {
						id: 'onlineTeam',
						text: 'Online Team',
						path: '/human-capital/hris/onlineTeam',
					},
				},
			},
			attendance: {
				id: 'attendance',
				text: 'Attendance',
				path: '/human-capital/attendance',
				subMenu: {
					attendanceModule: {
						id: 'attendanceModule',
						text: 'Attendance Module',
						path: '/human-capital/attendance/attendanceModule',
					},
					locationModule: {
						id: 'locationModule',
						text: 'Location Module',
						path: '/human-capital/attendance/locationModule',
					},
					cameraModule: {
						id: 'cameraModule',
						text: 'Camera Module',
						path: '/human-capital/attendance/cameraModule',
					},
				},
			},
			learningDevelopment: {
				id: 'learningDevelopment',
				text: 'Learning & Development',
				path: '/human-capital/learning-development',
				subMenu: {
					trainingModule: {
						id: 'trainingModule',
						text: 'Training Module',
						path: '/human-capital/learning-development/trainingModule',
					},
					evaluationModule: {
						id: 'evaluationModule',
						text: 'Evaluation Module',
						path: '/human-capital/learning-development/evaluationModule',
					},
					scoringModule: {
						id: 'scoringModule',
						text: 'Scoring Module',
						path: '/human-capital/learning-development/scoringModule',
					},
					kpiModule: {
						id: 'kpiModule',
						text: 'KPI Module',
						path: '/human-capital/learning-development/kpiModule',
					},
					quizModule: {
						id: 'quizModule',
						text: 'Quiz Module',
						path: '/human-capital/learning-development/quizModule',
					},
					learningModule: {
						id: 'learningModule',
						text: 'Learning Module',
						path: '/human-capital/learning-development/learningModule',
					},
				},
			},

		},
	},
	businessDevelopment: {
		id: 'businessDevelopment',
		text: 'Business Development',
		path: '/business-development',
		icon: 'CustomBriefCase',
		hasPage: true,
		subMenu: {
			dataCenter: {
				id: 'dataCenter',
				text: 'Data Center',
				path: '/business-development/data-center',
				hasPage: true,
				subMenu: {
					contractList: {
						id: 'contractList',
						text: 'Contract List',
						path: '/business-development/data-center/contractList',
					},
					projectList: {
						id: 'projectList',
						text: 'Project List',
						path: '/business-development/data-center/projectList',
					},
					progressList: {
						id: 'progressList',
						text: 'Progress List',
						path: '/business-development/data-center/progressList',
					},
					contractNotification: {
						id: 'contractNotification',
						text: 'Contract Notification',
						path: '/business-development/data-center/contractNotification',
					},
					dataOwner: {
						id: 'dataOwner',
						text: 'Owners Data',
						path: '/human-capital/dataOwner'
					}
				},
			},
			calendar: {
				id: 'calendar',
				text: 'Calendar',
				path: '/business-development/calendar',
				subMenu: {
					eventCalendar: {
						id: 'eventCalendar',
						text: 'Event Calendar',
						path: '/human-capital/calendar/eventCalendar',
						subMenu: {
							googleCalendarIntegration: {
								id: 'googleCalendarIntegration',
								text: 'Google Calendar Integration',
								path: '/human-capital/calendar/eventCalendar/googleCalendarIntegration',
							},
						},
					},
					projectManagementCalendar: {
						id: 'projectManagementCalendar',
						text: 'Project Management Calendar',
						path: '/human-capital/calendar/projectManagementCalendar',
					},
					humanRsourceCalendar: {
						id: 'humanRsourceCalendar',
						text: 'Human Capital Calendar',
						path: '/human-capital/calendar/humanRsourceCalendar',
					},
				},
			},
			projectManagement: {
				id: 'projectManagement',
				text: 'Project Management',
				path: '/business-development/project-management',
				subMenu: {
					projectTracker: {
						id: 'projectTracker',
						text: 'Project Tracker',
						path: '/human-capital/project-management/projectTracker',
						subMenu: {
							kanbanBoard: {
								id: 'kanbanBoard',
								text: 'Kanban Board',
								path: '/human-capital/project-management/projectTracker/kanbanBoard',
							},
							projectAssignment: {
								id: 'projectAssignment',
								text: 'Project Assignment',
								path: '/human-capital/project-management/projectTracker/projectAssignment',
							},
							notification: {
								id: 'notification',
								text: 'Notification',
								path: '/human-capital/project-management/projectTracker/notification',
							},
						},
					},
				},
			},
			leadsManagement: {
				id: 'leadsManagement',
				text: 'Leads management',
				path: '/business-development/leads-management',
				subMenu: {
					projectLeads: {
						id: 'projectLeads',
						text: 'Project Leads',
						path: '/human-capital/leads-management/projectLeads',
						hasPage: true
						// subMenu: {
						// 	hotLeads: {
						// 		id: 'hotLeads',
						// 		text: 'Hot Leads',
						// 		path: '/human-capital/leads-management/projectLeads/hotLeads',
						// 	},
						// 	coldLeads: {
						// 		id: 'coldLeads',
						// 		text: 'Cold Leads',
						// 		path: '/human-capital/leads-management/projectLeads/coldLeads',
						// 	},
						// 	pendingLeads: {
						// 		id: 'pendingLeads',
						// 		text: 'Pending Leads',
						// 		path: '/human-capital/leads-management/projectLeads/pendingLeads',
						// 	},
						// 	warmLeads: {
						// 		id: 'warmLeads',
						// 		text: 'Warm Leads',
						// 		path: '/human-capital/leads-management/projectLeads/warmLeads',
						// 	},
						// 	completedLeads: {
						// 		id: 'completedLeads',
						// 		text: 'Completed (Portfolio) Leads',
						// 		path: '/human-capital/leads-management/projectLeads/completedLeads',
						// 	},
						// },
					},
				},
			},
			salesTracker: {
				id: 'salesTracker',
				text: 'Sales Tracker',
				path: '/business-development/sales-tracker',
				subMenu: {
					// projectList: {
					// 	id: 'projectList',
					// 	text: 'Project List',
					// 	path: '/business-development/sales-tracker/projectList',
					// },
					salesCounter: {
						id: 'salesCounter',
						text: 'Sales Counter',
						path: '/business-development/sales-tracker/salesCounter',
					},
					salesKPI: {
						id: 'salesKPI',
						text: 'Sales KPI',
						path: '/business-development/sales-tracker/salesKPI',
					},
					clientTarget: {
						id: 'clientTarget',
						text: 'Client Target',
						path: '/business-development/sales-tracker/clientTarget',
					},
				},
			},
		},
	},
	operational: {
		id: 'operational',
		text: 'Operational',
		path: '/operational',
		icon: 'CustomBoard',
		subMenu: {
			onBoarding: {
				id: 'onBoarding',
				text: 'Onboarding',
				path: '/operational/property-summary',
				subMenu: {
					overviewCompany: {
						id: 'overviewCompany',
						text: 'Overview Company',
						path: '/operational/onboarding/overview-company',
					},
					regulation: {
						id: 'regulation',
						text: 'Regulation',
						path: '/operational/onboarding/regulation',
					},
					informationSystem: {
						id: 'informationSystem',
						text: 'Information System',
						path: '/operational/onboarding/information-system',
					},
				},
			},
			standardOperationProcedure: {
				id: 'standardOperationProcedure',
				text: 'Standard Operation Procedure',
				path: '/operational/standardOperationProcedure',
				subMenu: {
					frontOffice: {
						id: 'frontOffice',
						text: 'Front Office',
						path: '/operational/standardOperationProcedure/frontOffice',
					},
					houseKeeping: {
						id: 'houseKeeping',
						text: 'House Keeping',
						path: '/operational/standardOperationProcedure/houseKeeping',
					},
					foodAndBeverage: {
						id: 'foodAndBeverage',
						text: 'Food And Beverage',
						path: '/operational/standardOperationProcedure/foodAndBeverage',
					},
					kitchenOrFoodProduction: {
						id: 'kitchenOrFoodProduction',
						text: 'Kitchen Or Food Production',
						path: '/operational/standardOperationProcedure/kitchenOrFoodProduction',
					},
					engineeringAndMaintanance: {
						id: 'engineeringAndMaintanance',
						text: 'Engineering Maintanance',
						path: '/operational/standardOperationProcedure/engineeringAndMaintanance',
					},
					accountsAndCredit: {
						id: 'accountsAndCredit',
						text: 'Accounts And Credit',
						path: '/operational/standardOperationProcedure/accountsAndCredit',
					},
					securityDepartment: {
						id: 'securityDepartment',
						text: 'Security Department',
						path: '/operational/standardOperationProcedure/securityDepartment',
					},
					humanResources: {
						id: 'accounting',
						text: 'Human Resources (HR)',
						path: '/operational/standardOperationProcedure/humanResources',
					},
					salesAndMarketing: {
						id: 'salesAndMarketing',
						text: 'Sales and Marketing',
						path: '/operational/standardOperationProcedure/salesAndMarketing',
					},
					purchase: {
						id: 'purchase',
						text: 'Purchase',
						path: '/operational/standardOperationProcedure/purchase',
					},
					informationTechnology: {
						id: 'informationTechnology',
						text: 'Information Technology',
						path: '/operational/standardOperationProcedure/informationTechnology',
					},
				},
			},
			brandAudit: {
				id: 'brandAudit',
				text: 'Brand Audit',
				path: '/operational/brandAudit',
				subMenu: {
					schedule: {
						id: 'schedule',
						text: 'Audit Schedule',
						path: '/operational/brandAudit/schedule',
					},
					preAuditInternal: {
						id: 'preAuditInternal',
						text: 'Pre Audit Internal',
						path: '/operational/brandAudit/preAuditInternal',
					},
					checklistAudit: {
						id: 'checklistAudit',
						text: 'Checklist Audit',
						path: '/operational/brandAudit/checklistAudit',
					},
					auditPerformanceResults: {
						id: 'auditPerformanceResults',
						text: 'Audit Performance Result',
						path: '/operational/brandAudit/auditPerformanceResults',
					},
					valueAudit: {
						id: 'valueAudit',
						text: 'Value Audit',
						path: '/operational/brandAudit/valueAudit',
					},
				},
			},
			yearlyWorkingDays: {
				id: 'yearlyWorkingDays',
				text: 'Yearly Working Days',
				path: '/operational/yearly-working',
			},
			privacyPolicy: {
				id: 'privacyPolicy',
				text: 'Privacy & Policy',
				path: '/operational/privacy-policy',
			},
			community: {
				id: 'community',
				text: 'Community',
				path: '/operational/community',
			},
			articles: {
				id: 'articles',
				text: 'Articles',
				path: '/operational/articles',
			},
			faq: {
				id: 'faq',
				text: 'FAQ',
				path: '/operational/faq',
			},
		},
	},
	// authorizationAuthentication: {
	// 	id: 'authorizationAuthentication',
	// 	text: 'Authorization & Authentication',
	// 	icon: 'CustomSettings',
	// 	path: '/authorization-authentication',
	// 	subMenu: {
	// 		authenticationModule: {
	// 			id: 'authenticationModule',
	// 			text: 'Authentication Module',
	// 			path: '/business-intelligence/authentication-module',

	// 		},
	// 		authorizationModule: {
	// 			id: 'authorizationModule',
	// 			text: 'Authorization Module',
	// 			path: '/business-intelligence/authorization-module',

	// 		},
	// 	}
	// },
};

export const dummyMenu2 = {
	pmsApplication: {
		id: 'pmsApplication',
		text: 'PMS Application',
		path: '/pms',
		icon: 'CustomMobileProgramming',
		hasPage: true,
		subMenu: {
			roomManagement: {
				id: 'roomManagement',
				text: 'Room Management',
				path: '/room-management',
			},
			frontDeskModule: {
				id: 'frontDeskModule',
				text: 'Front desk Module',
				path: '/front-desk',
			},
			guestSupportModule: {
				id: 'guestSupportModule',
				text: 'Guest Support Module',
				path: '/guest-support',
			},
			guestModule: {
				id: 'guestModule',
				text: 'Guest Module',
				path: '/guest-module',
			},
			inventorAssetModule: {
				id: 'inventorAssetModule',
				text: 'Investor & Asset Module',
				path: '/investor-asset',
			},
			housekeepingModule: {
				id: 'housekeepingModule',
				text: 'Housekeeping Module',
				path: '/housekeeping',
			},
			pmsFoodBeverage: {
				id: 'pmsFoodBeverage',
				text: 'Food & Beverage',
				path: '/food-beverage',
			},
			taskSchedule: {
				id: 'taskSchedule',
				text: 'Task Schedule',
				path: '/task-schedule',
			},
			pmsFinanceAccounting: {
				id: 'pmsFinanceAccounting',
				text: 'Finance & Accounting',
				path: '/pms-finance',
			},
			pmsSalesMarketing: {
				id: 'pmsSalesMarketing',
				text: 'Sales & Marketing',
				path: '/pms-sales',
			},
		},
	},
	pmsChannelManager: {
		id: 'pmsChannelManager',
		text: 'Channel Manager',
		path: '/pms-channel-management',
		icon: 'CustomFolderOpen',
		subMenu: {
			dataDrivenDecision: {
				id: 'dataDrivenDecision',
				text: 'Data Driven Decision',
				path: '/data-driven',
			},
			otaIntegration: {
				id: 'otaIntegration',
				text: 'OTA Integration',
				path: '/ota-integration',
			},
			bookingEngine: {
				id: 'bookingEngine',
				text: 'Booking Engine Integration',
				path: '/booking-engine',
			},
			corporateSegment: {
				id: 'corporateSegment',
				text: 'Corporate Segment',
				path: '/corporate-segment',
			},
			dynamicPricing: {
				id: 'dynamicPricing',
				text: 'Dynamic Pricing',
				path: '/dynamic-pricing',
			},
			pricingManagement: {
				id: 'pricingManagement',
				text: 'Pricing Management',
				path: '/pricing-management',
			},
			omnichannel: {
				id: 'omnichannel',
				text: 'Omni-channel',
				path: '/omnichannel',
			},
		},
	},
	pmsBookingEngine: {
		id: 'pmsBookingEngine',
		text: 'Booking Engine',
		path: '/pms-booking-engine',
		icon: 'CustomFolderOpen',
		subMenu: {
			unitPricing: {
				id: 'unitPricing',
				text: 'Unit Pricing',
				path: '/unit-pricing',
			},
			roomBooking: {
				id: 'roomBooking',
				text: 'Room Booking',
				path: '/room-booking',
			},
			facilitiesBooking: {
				id: 'facilitiesBooking',
				text: 'Facilities Booking',
				path: '/facilities-booking',
			},
		},
	},
};

export const gettingStartedPagesMenu = {
	gettingStarted: {
		id: 'gettingStarted',
		text: 'Getting Started',
		path: 'getting-started',
		icon: 'Book',
		subMenu: {
			installation: {
				id: 'installation',
				text: 'Installation',
				path: 'getting-started/installation',
				icon: 'BuildCircle',
			},
			dev: {
				id: 'dev',
				text: 'Development',
				path: 'getting-started/development',
				icon: 'DirectionsRun',
			},
			folderStructure: {
				id: 'folderStructure',
				text: 'Folder Structure',
				path: 'getting-started/folder-structure',
				icon: 'SnippetFolder',
			},
			bootstrapVariables: {
				id: 'bootstrapVariables',
				text: 'Bootstrap Variables',
				path: 'getting-started/bootstrap-variables',
				icon: 'SnippetFolder',
			},
			projectStructure: {
				id: 'projectStructure',
				text: 'Project Structure',
				path: 'getting-started/project-structure',
				icon: 'SnippetFolder',
			},
		},
	},
	routes: {
		id: 'routes',
		text: 'Routes & Pages',
		path: 'routes',
		icon: 'AltRoute',
		subMenu: {
			router: {
				id: 'router',
				text: 'Router',
				path: 'routes/router',
				icon: 'Router',
			},
		},
	},
};

export const componentPagesMenu = {
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap',
		icon: 'Extension',
	},
	components: {
		id: 'components',
		text: 'Component',
		path: 'components',
		icon: 'Extension',
		notification: 'success',
		subMenu: {
			accordion: {
				id: 'accordion',
				text: 'Accordion',
				path: 'components/accordion',
				icon: 'ViewDay',
			},
			alert: {
				id: 'alert',
				text: 'Alert',
				path: 'components/alert',
				icon: 'Announcement',
			},
			badge: {
				id: 'badge',
				text: 'Badge',
				path: 'components/badge',
				icon: 'Vibration',
			},
			breadcrumb: {
				id: 'breadcrumb',
				text: 'Breadcrumb',
				path: 'components/breadcrumb',
				icon: 'AddRoad',
			},
			button: {
				id: 'button',
				text: 'Button',
				path: 'components/button',
				icon: 'SmartButton',
			},
			buttonGroup: {
				id: 'buttonGroup',
				text: 'Button Group',
				path: 'components/button-group',
				icon: 'Splitscreen',
			},
			card: {
				id: 'card',
				text: 'Card',
				path: 'components/card',
				icon: 'Crop32',
			},
			carousel: {
				id: 'carousel',
				text: 'Carousel',
				path: 'components/carousel',
				icon: 'RecentActors',
			},
			// Close
			collapse: {
				id: 'collapse',
				text: 'Collapse',
				path: 'components/collapse',
				icon: 'UnfoldLess',
			},
			dropdowns: {
				id: 'dropdowns',
				text: 'Dropdowns',
				path: 'components/dropdowns',
				icon: 'Inventory',
			},
			listGroup: {
				id: 'listGroup',
				text: 'List Group',
				path: 'components/list-group',
				icon: 'ListAlt',
			},
			modal: {
				id: 'modal',
				text: 'Modal',
				path: 'components/modal',
				icon: 'PictureInPicture',
			},
			navsTabs: {
				id: 'navsTabs',
				text: 'Navs & Tabs',
				path: 'components/navs-and-tabs',
				icon: 'PivotTableChart',
			},
			// Navbar
			offcanvas: {
				id: 'offcanvas',
				text: 'Offcanvas',
				path: 'components/offcanvas',
				icon: 'VerticalSplit',
			},
			pagination: {
				id: 'pagination',
				text: 'Pagination',
				path: 'components/pagination',
				icon: 'Money',
			},
			popovers: {
				id: 'popovers',
				text: 'Popovers',
				path: 'components/popovers',
				icon: 'Assistant',
			},
			progress: {
				id: 'progress',
				text: 'Progress',
				path: 'components/progress',
				icon: 'HourglassTop',
			},
			scrollspy: {
				id: 'scrollspy',
				text: 'Scrollspy',
				path: 'components/scrollspy',
				icon: 'KeyboardHide',
			},
			spinners: {
				id: 'spinners',
				text: 'Spinners',
				path: 'components/spinners',
				icon: 'RotateRight',
			},
			table: {
				id: 'table',
				text: 'Table',
				path: 'components/table',
				icon: 'TableChart',
			},
			toasts: {
				id: 'toasts',
				text: 'Toasts',
				path: 'components/toasts',
				icon: 'RotateRight',
			},
			tooltip: {
				id: 'tooltip',
				text: 'Tooltip',
				path: 'components/tooltip',
				icon: 'Assistant',
			},
		},
	},
	forms: {
		id: 'forms',
		text: 'Forms',
		path: 'forms',
		icon: 'CheckBox',
		notification: 'success',
		subMenu: {
			formGroup: {
				id: 'formGroup',
				text: 'Form Group',
				path: 'forms/form-group',
				icon: 'Source',
			},
			formControl: {
				id: 'formControl',
				text: 'Form Controls',
				path: 'forms/form-controls',
				icon: 'Create',
			},
			select: {
				id: 'select',
				text: 'Select',
				path: 'forms/select',
				icon: 'Checklist',
			},
			checksAndRadio: {
				id: 'checksAndRadio',
				text: 'Checks & Radio',
				path: 'forms/checks-and-radio',
				icon: 'CheckBox',
			},
			range: {
				id: 'range',
				text: 'Range',
				path: 'forms/range',
				icon: 'HdrStrong',
			},
			inputGroup: {
				id: 'inputGroup',
				text: 'Input Group',
				path: 'forms/input-group',
				icon: 'PowerInput',
			},
			validation: {
				id: 'validation',
				text: 'Validation',
				path: 'forms/validation',
				icon: 'VerifiedUser',
			},
			wizard: {
				id: 'wizard',
				text: 'Wizard',
				path: 'forms/wizard',
				icon: 'LinearScale',
			},
		},
	},
	content: {
		id: 'content',
		text: 'Content',
		path: 'content',
		icon: 'format_size',
		subMenu: {
			typography: {
				id: 'typography',
				text: 'Typography',
				path: 'content/typography',
				icon: 'text_fields',
			},
			images: {
				id: 'images',
				text: 'Images',
				path: 'content/images',
				icon: 'Image ',
			},
			tables: {
				id: 'tables',
				text: 'Tables',
				path: 'content/tables',
				icon: 'table_chart',
			},
			figures: {
				id: 'figures',
				text: 'Figures',
				path: 'content/figures',
				icon: 'Photo Library ',
			},
		},
	},
	utilities: {
		id: 'utilities',
		text: 'Utilities',
		path: 'utilities',
		icon: 'Support',
		subMenu: {
			api: {
				id: 'api',
				text: 'API',
				path: 'utilities/api',
				icon: 'Api',
			},
			background: {
				id: 'background',
				text: 'Background',
				path: 'utilities/background',
				icon: 'FormatColorFill',
			},
			borders: {
				id: 'borders',
				text: 'Borders',
				path: 'utilities/borders',
				icon: 'BorderStyle',
			},
			colors: {
				id: 'colors',
				text: 'Colors',
				path: 'utilities/colors',
				icon: 'InvertColors',
			},
			display: {
				id: 'display',
				text: 'Display',
				path: 'utilities/display',
				icon: 'LaptopMac',
			},
			flex: {
				id: 'flex',
				text: 'Flex',
				path: 'utilities/flex',
				icon: 'SettingsOverscan',
			},
			float: {
				id: 'float',
				text: 'Float',
				path: 'utilities/float',
				icon: 'ViewArray',
			},
			interactions: {
				id: 'interactions',
				text: 'Interactions',
				path: 'utilities/interactions',
				icon: 'Mouse',
			},
			overflow: {
				id: 'overflow',
				text: 'Overflow',
				path: 'utilities/overflow',
				icon: 'TableRows',
			},
			position: {
				id: 'position',
				text: 'Position',
				path: 'utilities/position',
				icon: 'Adjust',
			},
			shadows: {
				id: 'shadows',
				text: 'Shadows',
				path: 'utilities/shadows',
				icon: 'ContentCopy',
			},
			sizing: {
				id: 'sizing',
				text: 'Sizing',
				path: 'utilities/sizing',
				icon: 'Straighten',
			},
			spacing: {
				id: 'spacing',
				text: 'Spacing',
				path: 'utilities/spacing',
				icon: 'SpaceBar',
			},
			text: {
				id: 'text',
				text: 'Text',
				path: 'utilities/text',
				icon: 'TextFields',
			},
			verticalAlign: {
				id: 'vertical-align',
				text: 'Vertical Align',
				path: 'utilities/vertical-align',
				icon: 'VerticalAlignCenter',
			},
			visibility: {
				id: 'visibility',
				text: 'Visibility',
				path: 'utilities/visibility',
				icon: 'Visibility',
			},
		},
	},
	extra: {
		id: 'extra',
		text: 'Extra Library',
		icon: 'Extension',
		path: undefined,
	},
	icons: {
		id: 'icons',
		text: 'Icons',
		path: 'icons',
		icon: 'Grain',
		notification: 'success',
		subMenu: {
			icon: {
				id: 'icon',
				text: 'Icon',
				path: 'icons/icon',
				icon: 'Lightbulb',
			},
			material: {
				id: 'material',
				text: 'Material',
				path: 'icons/material',
				icon: 'Verified',
			},
		},
	},
	charts: {
		id: 'charts',
		text: 'Charts',
		path: 'charts',
		icon: 'AreaChart',
		notification: 'success',
		subMenu: {
			chartsUsage: {
				id: 'chartsUsage',
				text: 'General Usage',
				path: 'charts/general-usage',
				icon: 'Description',
			},
			chartsSparkline: {
				id: 'chartsSparkline',
				text: 'Sparkline',
				path: 'charts/sparkline',
				icon: 'AddChart',
			},
			chartsLine: {
				id: 'chartsLine',
				text: 'Line',
				path: 'charts/line',
				icon: 'ShowChart',
			},
			chartsArea: {
				id: 'chartsArea',
				text: 'Area',
				path: 'charts/area',
				icon: 'AreaChart',
			},
			chartsColumn: {
				id: 'chartsColumn',
				text: 'Column',
				path: 'charts/column',
				icon: 'BarChart',
			},
			chartsBar: {
				id: 'chartsBar',
				text: 'Bar',
				path: 'charts/bar',
				icon: 'StackedBarChart',
			},
			chartsMixed: {
				id: 'chartsMixed',
				text: 'Mixed',
				path: 'charts/mixed',
				icon: 'MultilineChart',
			},
			chartsTimeline: {
				id: 'chartsTimeline',
				text: 'Timeline',
				path: 'charts/timeline',
				icon: 'WaterfallChart',
			},
			chartsCandleStick: {
				id: 'chartsCandleStick',
				text: 'Candlestick',
				path: 'charts/candlestick',
				icon: 'Cake',
			},
			chartsBoxWhisker: {
				id: 'chartsBoxWhisker',
				text: 'Box Whisker',
				path: 'charts/box-whisker',
				icon: 'SportsMma',
			},
			chartsPieDonut: {
				id: 'chartsPieDonut',
				text: 'Pie & Donut',
				path: 'charts/pie-donut',
				icon: 'PieChart',
			},
			chartsRadar: {
				id: 'chartsRadar',
				text: 'Radar',
				path: 'charts/radar',
				icon: 'BrightnessLow',
			},
			chartsPolar: {
				id: 'chartsPolar',
				text: 'Polar',
				path: 'charts/polar',
				icon: 'TrackChanges',
			},
			chartsRadialBar: {
				id: 'chartsRadialBar',
				text: 'Radial Bar',
				path: 'charts/radial-bar',
				icon: 'DonutLarge',
			},
			chartsBubble: {
				id: 'chartsBubble',
				text: 'Bubble',
				path: 'charts/bubble',
				icon: 'BubbleChart',
			},
			chartsScatter: {
				id: 'chartsScatter',
				text: 'Scatter',
				path: 'charts/scatter',
				icon: 'ScatterPlot',
			},
			chartsHeatMap: {
				id: 'chartsHeatMap',
				text: 'Heat Map',
				path: 'charts/heat-map',
				icon: 'GridOn',
			},
			chartsTreeMap: {
				id: 'chartsTreeMap',
				text: 'Tree Map',
				path: 'charts/tree-map',
				icon: 'AccountTree',
			},
		},
	},
	notification: {
		id: 'notification',
		text: 'Notification',
		path: 'notifications',
		icon: 'NotificationsNone',
	},
	hooks: {
		id: 'hooks',
		text: 'Hooks',
		path: 'hooks',
		icon: 'Anchor',
	},
};

export const productsExampleMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
