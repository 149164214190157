import React from 'react';
import LogoArtotel from '../assets/img/Logo_ARTOTEL.png';
import LogoBobotel from '../assets/img/logo_Bobotel.png';
import LogoDHM from '../assets/img/Logo_DHM.png';
import LogoFEH from '../assets/img/Logo_FEH.png';
import LogoKyriad from '../assets/img/Logo_KYRIAD.png';
import LogoRIS from '../assets/img/Logo_RIS.png';

const LogoGrid = () => {
	return (
		<div id={'logoGrid'} className='container my-3'>
			<div className='row g-3'>
				<div className='col-4'>
					<div className='text-center w-100'>
						<img src={LogoArtotel} alt={LogoArtotel} width={'100%'} />
					</div>
				</div>
				<div className='col-4'>
					<div className='text-center w-100'>
						<img src={LogoBobotel} alt={LogoBobotel} width={'100%'} />
					</div>
				</div>
				<div className='col-4'>
					<div className='text-center w-100'>
						<img src={LogoDHM} alt={LogoDHM} width={'100%'} />
					</div>
				</div>
				<div className='col-4'>
					<div className='text-center w-100'>
						<img src={LogoFEH} alt={LogoFEH} width={'100%'} />
					</div>
				</div>
				<div className='col-4'>
					<div className='text-center w-100'>
						<img src={LogoKyriad} alt={LogoKyriad} width={'100%'} />
					</div>
				</div>
				<div className='col-4'>
					<div className='text-center w-100'>
						<img src={LogoRIS} alt={LogoRIS} width={'100%'} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default LogoGrid;
